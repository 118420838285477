<template>
    <div class="login-wrap">
        <div class="hw-login">
            <div class="hw-title">拨雾</div>
            <el-form ref="loginForm" :model="loginForm" :rules="rules" @submit.prevent="submitForm('loginForm')">
                <el-form-item prop="username">
                    <el-input v-model="loginForm.username" placeholder="请输入账号" :autofocus="true"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"></el-input>
                </el-form-item>
<!--                <el-form-item prop="code">-->
<!--                    <el-input v-model="loginForm.code" type="text" placeholder="请输入动态口令" style="width:140px"></el-input>-->
<!--                    如何获取动态口令?-->
<!--                </el-form-item>-->
                <div class="login-btn">
                    <el-button type="primary" native-type="submit"  @click.prevent="submitForm('loginForm')">{{loading ? '登录中' : '登录'}}</el-button>
                </div>
            </el-form>
        </div>

        <el-dialog title="动态口令" :visible.sync="keyPop" :modal="false" :close-on-click-modal="false" top="25vh" width="500px">
            <div class="key-wrap">
                <el-input v-model="keycode" placeholder="请输动态口令" size="large" style="text-align: center;font-size: 20px"></el-input>
                <p><el-button type="primary" :loading="loadingBtn"  @click.prevent="validateCode">{{loadingBtn ? '正在验证' : '确定'}}</el-button></p>
            </div>
            <div class="msg">
                <p class="red"><i class="el-icon-info"></i> 温馨提示：</p>
                <p><i class="el-icon-check"></i> 安卓和苹果手机请在应用市场搜索“<span style="color: #f60;margin:6px">Google Authenticator</span>”，下载安装即可。</p>
                <p><i class="el-icon-check"></i> 安装完毕后可添加一组账户和密钥，初始密钥请联系系统管理员获取。</p>
                <p><a href="/helper.html" target="_blank">查看使用教程</a> </p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                loading: false,
                codeImg: '',
                loginForm: {
                    username: '',
                    password: '',
                    code: ''
                },
                rules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'change'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'change'}
                    ],
                    code: [
                        {required: true, message: '请输入验证码', trigger: 'change'}
                    ]
                },
                keyPop: false,
                keycode: '',
                userId: null,
                loadingBtn: false,
            }
        },

        methods: {
            submitForm (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.$post(this.$api.Login, this.loginForm).then((res) => {
                            this.loading = false;
                            if (res.code === 200 || res.code === 0) {
                                this.userId = res.data.userid;
                                this.keyPop = true; //弹出双因素认证框
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    } else {
                        console.log('error submit!!')
                        // return false
                    }
                })
            },
            //验证口令
            validateCode() {
                if (this.keycode == '') {
                    this.$message.error('请输入动态口令');
                    return false;
                }
                this.loadingBtn = true;
                this.$post(this.$api.AuthValidate, {code: this.keycode, userid: this.userId}).then((res) => {
                    this.loadingBtn = false;
                    if (res.code === 200 || res.code === 0) {
                        sessionStorage.setItem('uname', res.data.uname);
                        sessionStorage.setItem('access-token', res.data.access_token);
                        this.$message({
                            message: '登录成功！',
                            type: 'success'
                        });
                        this.$router.push('/main');
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            // refreshCode () {
            //   this.codeImg = '/api/login/verify?'+ Math.random()*1000;
            // }
        },
    }
</script>

<style scoped lang="less">
    .login-wrap{
        position: fixed;
        width: 100%;
        height: 100%;
        background: #1F2D3D url(../assets/login-bg.jpg) center center;
        background-size: cover;
    }
    .hw-title{
        font-weight: bold;
        font-size: 1.4rem;
        height: 62px;
        line-height: 62px;
        text-align: center;
        color: #fff;
    }
    .hw-login{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 360px;
        height: 250px;
        margin: -125px 0 0 -180px;
        padding: 15px 25px;
        border-radius: 5px;
        background: rgba(255,255,255,.2);
        box-shadow: 2px 2px 2px #666;
    }
    .el-button{
        width: 100%
    }
    .key-wrap{
        padding: 0 20px;
        p{
            line-height: 42px;
            margin-top: 15px;
        }
    }
    .msg{
        margin: 20px;
        p{
            line-height: 32px;
            a{
                text-decoration: underline;
            }
        }
        p.red{
            color: #f60;
            font-weight: bold;
        }
    }
</style>
